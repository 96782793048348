<template>
  <div>
    <el-row>
      <!-- <el-col :span="6" class="flex-row-maring">
        <span class="span1">品类名称</span>
        <el-input v-model="searchParams.name" style="width: 60%;" placeholder="请输入内容" clearable></el-input>
      </el-col>
      <el-col :span="6" class="flex-row-maring">
        <span class="span1">种类</span>
        <el-select style="width: 60%;" v-model="searchParams.resourceType" placeholder="请选择" clearable>
          <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id"> </el-option>
        </el-select>
      </el-col> -->
      <el-col :span="6" class="flex-row-maring">
        <span class="span1">出入库</span>
        <el-select style="width: 60%" v-model="searchParams.businessType" placeholder="请选择" clearable>
          <el-option v-for="item in businessTypeList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
        </el-select>
      </el-col>
      <el-col :span="6" class="flex-row-maring">
        <span class="span1">部门名称</span>
        <el-select style="width: 60%" v-model="searchParams.departmentId" placeholder="请选择" clearable>
          <el-option v-for="item in departmentsOpitons" :key="item.id" :label="item.name" :value="item.id"> </el-option>
        </el-select>
      </el-col>
      <el-col :span="6" class="flex-row-maring">
        <span class="span1">操作人</span>
        <el-select style="width: 60%" v-model="searchParams.userId" placeholder="请选择" clearable>
          <el-option v-for="item in userOpitons" :key="item.id" :label="item.name" :value="item.id"> </el-option>
        </el-select>
        <!-- <el-input v-model="searchParams.name" style="width: 60%;" placeholder="请输入内容" clearable></el-input> -->
      </el-col>
      <el-col :span="6" class="flex-row-maring">
        <el-button type="primary" @click="search">搜索</el-button>
      </el-col>
    </el-row>
    <el-row type="flex" style="margin-top: 12px" justify="end">
      <el-button type="primary" @click="exportExcel">导出</el-button>
    </el-row>
    <div style="height: 20px"></div>

    <el-table :data="tableData" border style="width: 100%" @cell-click="onCellClick">
      <template slot="empty">
        <img style="width: 80%; height: 100%" src="@/assets/empty.jpeg" alt="" />
      </template>
      <el-table-column prop="updateTime" label="时间"> </el-table-column>
      <el-table-column prop="agriculturalProductionName" label="品类名称"> </el-table-column>
      <el-table-column prop="questionType" label="类型">
        <template slot-scope="scope">
          {{ getType(scope.row.resourceType) }}
        </template>
      </el-table-column>
      <el-table-column prop="name" label="出入库">
        <template slot-scope="scope">
          {{ scope.row.businessType == 1 ? '入库' : '出库' }}
        </template>
      </el-table-column>
      <el-table-column prop="unitSpecs" label="规格"> </el-table-column>
      <el-table-column prop="amount" label="件数"> </el-table-column>
      <el-table-column prop="descriptions" label="备注"> </el-table-column>
      <el-table-column prop="departmentName" label="所属部门"> </el-table-column>
      <el-table-column prop="userName" label="操作人"> </el-table-column>
    </el-table>

    <div class="pagenation">
      <el-pagination
        v-if="pageInfo.totalPage > 0"
        background
        @current-change="handleSizeChange"
        :current-page.sync="pageInfo.currentPage"
        :page-size="pageInfo.pageSize"
        layout="total, prev, pager, next"
        :total="pageInfo.totalPage"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { recordList, exportStockHistory } from '@/api/stockManage1/api.js';
export default {
  components: {},
  props: {
    allDic: {},
    userOpitons: [],
    departmentsOpitons: []
  },
  data() {
    return {
      dialogVisible: false,
      isEdit: false,
      expertName: '',
      applytDate: [],
      startDate: [],
      currentId: '',
      questionType: '',
      searchParams: {
        userId: '',
        departmentId: '',
        businessType: ''
      },
      businessTypeList: [
        {
          id: '',
          name: '全部'
        },
        {
          id: 1,
          name: '入库'
        },
        {
          id: 2,
          name: '出库'
        }
      ],
      pageInfo: {
        currentPage: 1,
        pageSize: 15,
        totalPage: 0
      },
      activeName: '1',

      tableData: [],
      options: [],
      questionTypeDic: null
    };
  },
  created() {},
  mounted() {
    this.getList();
  },
  methods: {
    exportExcel() {
      exportStockHistory(this.searchParams)
        .then((res) => {})
        .catch((error) => {});
    },

    getType(key) {
      if (!this.allDic) {
        return;
      }
      return this.allDic['agriculture.resource.type'][key];
    },

    toDetail(item) {
      this.currentId = item.id;
      this.dialogVisible = true;
    },
    toEdit(item) {
      this.currentId = item.id;
      this.isEdit = true;
      this.dialogVisible = true;
    },

    getQuestionType(key) {
      return this.questionTypeDic[key];
    },
    onCellClick(row) {
      // this.$router.push({ name: 'detailService', query: { id: row.id, from: 'metting' } })
    },
    search() {
      this.pageInfo.currentPage = 1;
      this.getList();
    },
    toEdit(item) {
      this.currentId = item.id;
      this.isEdit = true;
      this.dialogVisible = true;
    },
    handleClick(row) {
      this.pageInfo.currentPage = 1;
      this.getList();
    },
    getList() {
      recordList({ pageNum: this.pageInfo.currentPage, pageSize: this.pageInfo.pageSize, ..._.pickBy({ ...this.searchParams }) })
        .then((res) => {
          this.tableData = res.rows;
          this.pageInfo.totalPage = res.total;
        })
        .catch((error) => {});
    },
    handleSizeChange(e) {
      this.pageInfo.currentPage = e;
      this.getList();
    },
    confirmApply(e) {
      console.log(e);
    }
  }
};
</script>
<style scoped lang="scss">
.flex-row-maring {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;

  .span1 {
    padding-right: 24px;
    font-size: inherit;
    font-size: 12px;
  }
}
</style>
