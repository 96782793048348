<template>
  <el-dialog title="详情" :visible="dialogVisible" width="80%" @close="closeMe" destroy-on-close :close-on-click-modal="false">
    <el-row type="flex" style="margin: 12px 0" justify="end">
      <el-button type="primary" @click="exportExcel">导出</el-button>
    </el-row>
    <el-table v-if="dialogVisible" ref="multipleTable" :data="tableData" border style="width: 100%" height="50vh">
      <template slot="empty">
        <img style="width: 80%; height: 100%" src="@/assets/empty.jpeg" alt="" />
      </template>
      <el-table-column prop="productCode" label="品类编号"> </el-table-column>
      <el-table-column prop="questionType" label="类型">
        <template slot-scope="scope">
          {{ getType(scope.row.resourceType) }}
        </template>
      </el-table-column>
      <el-table-column prop="agriculturalProductionName" label="品类名称"> </el-table-column>
      <el-table-column prop="unitSpecs" label="规格"> </el-table-column>
      <el-table-column prop="unitAmount" label="总数量"> </el-table-column>
      <el-table-column prop="availableAmount" label="目前库存"> </el-table-column>
      <el-table-column prop="address" label="已用">
        <template slot-scope="scope">
          {{ scope.row.unitAmount - scope.row.availableAmount }}
        </template>
      </el-table-column>
      <el-table-column prop="updateTime" label="更新时间"> </el-table-column>
    </el-table>
    <div>
      <el-pagination
        v-if="pageInfo.totalPage > 0"
        background
        @current-change="handleSizeChange"
        :current-page.sync="pageInfo.currentPage"
        :page-size="pageInfo.pageSize"
        layout="total, prev, pager, next"
        :total="pageInfo.totalPage"
      >
      </el-pagination>
    </div>
  </el-dialog>
</template>
<script>
import { getRequest, exportFile } from '@/api/api';
export default {
  props: {
    dialogVisible: false,
    currentId: '',
    userOpitons: [],
    departmentsOpitons: [],
    currentItem: {}
  },
  components: {},
  computed: {},
  data() {
    return {
      tableData: [],
      allDic: {},
      searchParams: {
        name: ''
      },
      departmentId: '',
      options: [],
      pageInfo: {
        currentPage: 1,
        pageSize: 15,
        totalPage: 0
      },
      dialog: false
    };
  },
  created() {},
  destroyed() {},
  mounted() {},
  watch: {
    async dialogVisible(val) {
      this.multipleSelection = [];

      this.pageInfo = {
        currentPage: 1,
        pageSize: 15,
        totalPage: 0
      };
      this.searchParams = {
        name: ''
      };
      this.allDic = await this.$store.dispatch('getDicAll');
      if (val) {
        this.getList();
      }
    }
  },
  methods: {
    exportExcel() {
      exportFile('/api/operate/govserv/productionreport/seed/department/stock/expert', {
        departmentId: this.currentId,
        pageNum: this.pageInfo.currentPage,
        pageSize: this.pageInfo.pageSize,
        ..._.pickBy({ ...this.searchParams })
      });
    },
    toDetail(item) {
      this.departmentId = item.departmentId;
      this.dialog = true;
    },

    getType(key) {
      if (!this.allDic) {
        return;
      }
      return this.allDic['agriculture.resource.type'][key];
    },
    getunit(key) {
      return this.allDic['unit.of.measurement'][key];
    },
    getMeasurement(key) {
      return this.allDic['unit.specs'][key];
    },
    updateList() {
      this.$emit('updateList');
    },
    closeMe() {
      this.$emit('update:dialogVisible', false);
    },
    search() {
      this.pageInfo.currentPage = 1;
      this.getList();
    },
    getRowKey(row) {
      return row.id;
    },
    handleSizeChange(e) {
      this.pageInfo.currentPage = e;
      this.getList();
    },
    getList() {
      console.log(this.currentId);
      getRequest('/api/operate/govserv/productionreport/seed/department/stock', {
        departmentId: this.currentId,
        pageNum: this.pageInfo.currentPage,
        pageSize: this.pageInfo.pageSize,
        ..._.pickBy({ ...this.searchParams })
      })
        .then((res) => {
          this.tableData = res;
        })
        .catch((error) => {});
    }
  }
};
</script>
<style scoped lang="scss">
.flex-center {
  height: 40px;
  line-height: 40px;
}
</style>
