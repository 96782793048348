<template>
  <el-dialog title="详情" :visible="dialogVisible" width="80%" @close="closeMe" destroy-on-close :close-on-click-modal="false">
    <!-- <el-row style="margin-bottom: 12px;">
      <el-col :span="6" class="flex-row">
        <span class="span1">操作人</span>
        <el-input v-model="searchParams.productCode" style="width: 60%;" placeholder="请输入内容" clearable></el-input>
      </el-col>
      <el-col :span="6" class="flex-row">
        <span class="span1">出入库</span>
        <el-select style="width: 60%;" v-model="searchParams.resourceType" placeholder="请选择" clearable>
          <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id"> </el-option>
        </el-select>
      </el-col>
      <el-col :span="6" class="flex-row">
        <span class="span1">部门</span>
        <el-select style="width: 60%;" v-model="searchParams.resourceType" placeholder="请选择" clearable>
          <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id"> </el-option>
        </el-select>
      </el-col>
      <el-col :span="6" class="flex-row">
        <el-button type="primary" @click="search">搜索</el-button>
      </el-col>
    </el-row> -->
    <!-- <el-row type="flex" style="margin: 12px 0" justify="end">
      <el-button type="primary" @click="exportExcel">导出</el-button>
    </el-row> -->
    <el-table
      v-if="dialogVisible"
      ref="multipleTable"
      :data="tableData"
      border
      style="width: 100%;"
      height="50vh"
      :row-key="getRowKey"
      @selection-change="handleSelectionChange"
    >
      <template slot="empty">
        <img style="width: 80%;height: 100%;" src="@/assets/empty.jpeg" alt="" />
      </template>
      <el-table-column prop="departmentName" label="部门名称"> </el-table-column>
      <el-table-column prop="productCode" label="品类编号">
        <template slot-scope="scope">
          {{ currentItem.productCode }}
        </template>
      </el-table-column>
      <el-table-column prop="questionType" label="类型">
        <template slot-scope="scope">
          {{ getType(currentItem.resourceType) }}
        </template>
      </el-table-column>
      <el-table-column label="品类名称">
        <template slot-scope="scope">
          {{ currentItem.name }}
        </template>
      </el-table-column>
      <el-table-column prop="unitSpecs" label="规格"> </el-table-column>
      <el-table-column prop="unitAmount" label="总数量"> </el-table-column>
      <el-table-column prop="availableAmount" label="目前库存"> </el-table-column>
      <el-table-column prop="address" label="已用">
        <template slot-scope="scope">
          {{ scope.row.unitAmount - scope.row.availableAmount }}
        </template>
      </el-table-column>
      <el-table-column prop="updateTime" label="更新时间"> </el-table-column>
      <el-table-column fixed="right" label="操作" width="100">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="toDetail(scope.row)">出入库详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div>
      <el-pagination
        v-if="pageInfo.totalPage > 0"
        background
        @current-change="handleSizeChange"
        :current-page.sync="pageInfo.currentPage"
        :page-size="pageInfo.pageSize"
        layout="total, prev, pager, next"
        :total="pageInfo.totalPage"
      >
      </el-pagination>
    </div>
    <stockDetail
      :allDic="allDic"
      :userOpitons="userOpitons"
      :departmentsOpitons="departmentsOpitons"
      :dialogVisible.sync="dialog"
      :currentId="currentId"
      :departmentId="departmentId"
    >
    </stockDetail>
  </el-dialog>
</template>
<script>
import { departments, startPlantingWithScheme } from '@/api/stockManage1/api';
import stockDetail from './stockDetail.vue';
export default {
  props: {
    dialogVisible: false,
    currentId: '',
    allDic: {},
    userOpitons: [],
    departmentsOpitons: [],
    currentItem: {}
  },
  components: { stockDetail },
  computed: {},
  data() {
    return {
      tableData: [],
      searchParams: {
        name: ''
      },
      departmentId: '',
      options: [],
      pageInfo: {
        currentPage: 1,
        pageSize: 15,
        totalPage: 0
      },
      dialog: false
    };
  },
  created() {},
  destroyed() {},
  mounted() {},
  watch: {
    dialogVisible(val) {
      this.multipleSelection = [];
      this.pageInfo = {
        currentPage: 1,
        pageSize: 15,
        totalPage: 0
      };
      this.searchParams = {
        name: ''
      };

      if (val) {
        console.log(this.currentItem, '@@@@');
        this.getList();
      }
    }
  },
  methods: {
    exportExcel() {},
    toDetail(item) {
      this.departmentId = item.departmentId;
      this.dialog = true;
    },

    getType(key) {
      if (!this.allDic) {
        return;
      }
      return this.allDic['agriculture.resource.type'][key];
    },

    onSubmit() {
      if (!this.multipleSelection.length) {
        this.$message('请选择地块');
        return;
      }
      let ids = this.multipleSelection.map(e => {
        return e.id;
      });
      let formData = new FormData();
      formData.append('schemeId', this.$route.query.id);
      formData.append('landIds', ids);
      startPlantingWithScheme(formData).then(res => {
        this.$message({
          message: '创建成功',
          type: 'success'
        });
        this.closeMe();
        this.updateList();
      }).catch(error => {

      });
      console.log('');
    },
    updateList() {
      this.$emit('updateList');
    },
    closeMe() {
      this.$emit('update:dialogVisible', false);
    },
    search() {
      this.pageInfo.currentPage = 1;
      this.getList();
    },
    getRowKey(row) {
      return row.id;
    },
    handleSelectionChange(val) {
      console.log('@选中的值', val);
      this.multipleSelection = val;
    },
    handleSizeChange(e) {
      this.pageInfo.currentPage = e;
      this.getList();
    },
    getList() {
      departments({
        productionId: this.currentId,
        pageNum: this.pageInfo.currentPage,
        pageSize: this.pageInfo.pageSize,
        ..._.pickBy({ ...this.searchParams })
      }).then(res => {
        this.tableData = res;
      }).catch(error => {

      });
    }
  }
};
</script>
<style scoped lang="scss">
.flex-center {
  height: 40px;
  line-height: 40px;
}
</style>
